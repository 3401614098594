import React from 'react';

import Layout from '../components/Layout';
// import Divider from '../components/Divider';
import Home from './Home';
// import About from './About';
import News from './News';
// import Team from './Team';
//import Join from './Join';
// import Work from './Work';
//import { motion, AnimatePresence } from 'framer-motion';

const Index: React.FC = () => 
{
  return (
    <>
        
          <Home/>
          <News/>
        
    </>
  );
}

export default Index;
